import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`6th July 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now easily send and receive RESTful APIs using `}<a parentName="p" {...{
            "href": "type://RestClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RestClient.html"
          }}>{`type://RestClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4263"
          }}>{`#4263`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Java`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`RestClient restClient = RestClient.of("...");
CompletableFuture<ResponseEntity<Customer>> response =
    restClient.get("/api/v1/customers/{customerId}")
              .pathParam("customerId", "0000001")
              .execute(Customer.class);
`}</code></pre>
          </li>
          <li parentName="ul">{`Kotlin`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-kotlin"
              }}>{`val restClient: RestClient = RestClient.of("...");
val response: ResponseEntity<Customer> =
   restClient
     .get("/api/v1/customers/{customerId}")
     .pathParam("customerId", "0000001")
     .execute<Customer>()  // a suspend function
`}</code></pre>
          </li>
          <li parentName="ul">{`Scala`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-scala"
              }}>{`val restClient: ScalaRestClient = ScalaRestClient("...")
val response: Future[ResponseEntity[Result]] =
  restClient.post("/api/v1/customers")
            .contentJson(new Customer(...))
            .execute[Result]()
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure a timeout for an `}<a parentName="p" {...{
            "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`type://Endpoint`}</a>{` selection of an
`}<a parentName="p" {...{
            "href": "type://DynamicEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/DynamicEndpointGroup.html"
          }}>{`type://DynamicEndpointGroup`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4246"
          }}>{`#4246`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsAddressEndpointGroup delegate = DnsAddressEndpointGroup.of(...);
HealthCheckedEndpointGroup healthGroup =
  HealthCheckedEndpointGroup
    .builder(delegate, "/health")
    .selectionTimeout(Duration.ofSeconds(10)) // 👈👈👈
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now inject dependencies in an annotation using `}<a parentName="p" {...{
            "href": "type://DependencyInjector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/DependencyInjector.html"
          }}>{`type://DependencyInjector`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4006"
          }}>{`#4006`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4202"
          }}>{`#4202`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Inject authClient that is needed to create the AuthDecorator.
WebClient authClient = ...
DependencyInjector injector =
    DependencyInjector.ofSingletons(new AuthDecorator(authClient));
serverBuilder.dependencyInjector(dependencyInjector, true);

// An annotated service that uses AuthDecorator.
@Get("/foo")
@Decorator(AuthDecorator.class)
public FooResponse foo(FooRequest req) {
    // Authrorized request.
    ...
}

// authClient is injected.
class AuthDecorator implements DecoratingHttpServiceFunction {
    AuthDecorator(WebClient authClient) { ... }

    @Override
    public HttpResponse serve(HttpService delegate,
                              ServiceRequestContext ctx,
                              HttpRequest req)
            throws Exception {
        // Authorize the request.
        ...
    }
}
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`Set `}<inlineCode parentName="li">{`armeria.enable-auto-injection`}</inlineCode>{` to `}<inlineCode parentName="li">{`true`}</inlineCode>{` to apply `}<a parentName="li" {...{
              "href": "type://SpringDependencyInjector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/SpringDependencyInjector.html"
            }}>{`type://SpringDependencyInjector`}</a>{` automatically
when using Spring Boot integration.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-yaml"
              }}>{`// in application.yml
armeria:
  ports:
    ...
  enable-auto-injection: true  // 👈👈👈
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the length of string fields or container fields
of Thrift messages using `}<a parentName="p" {...{
            "href": "type://THttpServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpServiceBuilder.html"
          }}>{`type://THttpServiceBuilder`}</a>{` or `}<a parentName="p" {...{
            "href": "type://ThriftClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/thrift/ThriftClientBuilder.html"
          }}>{`type://ThriftClientBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4024"
          }}>{`#4024`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4226"
          }}>{`#4226`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`THttpService.builder()
            .addService(new MyThriftService())
            .maxRequestStringLength(MAX_STRING_LENGTH)
            .maxRequestContainerLength(MAX_CONTAINER_LENGTH)
            .build();
ThriftClients.builder("https://my.server.com")
             .path("/thrift")
             .maxResponseStringLength(MAX_STRING_LENGTH)
             .maxResponseContainerLength(MAX_CONTAINER_LENGTH)
             .build(HelloService.AsyncIface.class);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set attributes to an `}<a parentName="p" {...{
            "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`type://Endpoint`}</a>{` using `}<a parentName="p" {...{
            "href": "type://Attributes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Attributes.html"
          }}>{`type://Attributes`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://AttributesBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AttributesBuilder.html"
          }}>{`type://AttributesBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4241"
          }}>{`#4241`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`AttributeKey<String> region = AttributeKey.valueOf(MyAttrs.class, "region");
Attributes attrs =
  Attributes
    .builder()
    .set(region, "us-west-1")
    .build();

Endpoint endpointA = ...;
Endpoint endpointB = endpointA.withAttrs(attrs);
assert endpointB.attr(region).equals("us-west-1");
assert !endpointA.equals(endpointB)
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://MultipartFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/MultipartFile.html"
          }}>{`type://MultipartFile`}</a>{` to get the actual filename of an uploaded file
through `}<inlineCode parentName="p">{`multipart/form-data`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4262"
          }}>{`#4262`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Consumes(MediaTypeNames.MULTIPART_FORM_DATA)
@Post("/upload")
public HttpResponse upload(MultipartFile multipartFile) {
  // The name parameter of the "content-disposition" header
  String name = multipartFile.name();
  // The filename parameter of the "content-disposition" header
  String filename = multipartFile.filename();
  // The file that stores the multipart content.
  File file = multipartFile.file();
  ...
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now read a range of bytes using `}<a parentName="p" {...{
            "href": "type://ByteStreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ByteStreamMessage.html"
          }}>{`type://ByteStreamMessage`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4058"
          }}>{`#4058`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Path path = ...;
// Fluently build a \`StreamMessage\` for a \`Path\`.
ByteStreamMessage pathContent =
    StreamMessage.builder(path)
                 .bufferSize(1024)
                 .alloc(alloc)
                 .executor(executor)
                 .build();
// Slice a range of a file data
ByteStreamMessage partialContent = pathContent.range(100, 200);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now map a specific exception to a `}<inlineCode parentName="p">{`LogLevel`}</inlineCode>{` in `}<a parentName="p" {...{
            "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
          }}>{`type://LoggingService`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
          }}>{`type://LoggingClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3400"
          }}>{`#3400`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4090"
          }}>{`#4090`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`LoggingService.builder()
              .responseLogLevel(IllegalStateException.class,
                                LogLevel.WARN)
              .responseLogLevel(IllegalArgumentException.class,
                                LogLevel.ERROR)
              ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now map a specific exception to an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4279"
          }}>{`#4279`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4283"
          }}>{`#4283`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse recovered =
    response.recover(IllegalStateException.class,
                     cause -> HttpResponse.of("Fallback"));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now enable automatic compression for a gRPC response. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4258"
          }}>{`#4258`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4266"
          }}>{`#4266`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .addService(new MyGrpcService())
           .autoCompression(true)
           .build();
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`This only works when the client sends a `}<inlineCode parentName="li">{`grpc-accept-encoding`}</inlineCode>{` header and
the `}<inlineCode parentName="li">{`CompressorRegistry`}</inlineCode>{` has the compressor.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add a hook to `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` and `}<a parentName="p" {...{
            "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
          }}>{`type://ClientFactory`}</a>{` that is called when the
JVM shuts down. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4015"
          }}>{`#4015`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4043"
          }}>{`#4043`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`server.closeOnJvmShutdown(() -> {
  System.err.println("Server is stopping soon.");
}).thenRun(() -> {
  System.err.println("Server has stopped.");
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize internal Netty `}<inlineCode parentName="p">{`ChannelPipeline`}</inlineCode>{` using
`}<a parentName="p" {...{
            "href": "type://ClientFactoryOptions#CHANNEL_PIPELINE_CUSTOMIZER:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOptions.html#CHANNEL_PIPELINE_CUSTOMIZER"
          }}>{`type://ClientFactoryOptions#CHANNEL_PIPELINE_CUSTOMIZER`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3907"
          }}>{`#3907`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4260"
          }}>{`#4260`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This is an advanced feature and not recommended to use if you are not familiar with
Armeria and Netty internals.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "kotlin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#kotlin",
        "aria-label": "kotlin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kotlin`}</h3>
    <ul>
      <li parentName="ul">{`You can now use the Kotlin nullable type (`}<inlineCode parentName="li">{`?`}</inlineCode>{`) to indicate a nullable parameter in
an annotated service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4144"
        }}>{`#4144`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4225"
        }}>{`#4225`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`serverBuilder.apply {
  annotatedService(object {
    // Both \`/foo\` and \`/foo?a=bar\` are allowed.
    @Get("/foo")
    fun foo(@Param a: String?) = // 👈👈👈
      HttpResponse.of("a: $a")
  })
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://EndpointSelectionTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionTimeoutException.html"
        }}>{`type://EndpointSelectionTimeoutException`}</a>{` is thrown if timeout occurred when selecting
an `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` from an `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4269"
        }}>{`#4269`}</a></li>
      <li parentName="ul">{`You can now set multiple `}<a parentName="li" {...{
          "href": "typeplural://AccessLogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html"
        }}>{`typeplural://AccessLogWriter`}</a>{` to `}<a parentName="li" {...{
          "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
        }}>{`type://ServerBuilder`}</a>{`
and `}<a parentName="li" {...{
          "href": "type://ServiceBindingBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceBindingBuilder.html"
        }}>{`type://ServiceBindingBuilder`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4280"
        }}>{`#4280`}</a></li>
      <li parentName="ul">{`You can now give a performance optimization hint to a client by setting an `}<a parentName="li" {...{
          "href": "type://ExchangeType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ExchangeType.html"
        }}>{`type://ExchangeType`}</a>{` to a
`}<a parentName="li" {...{
          "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
        }}>{`type://RequestOptions`}</a>{`.
The `}<a parentName="li" {...{
          "href": "type://ExchangeType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ExchangeType.html"
        }}>{`type://ExchangeType`}</a>{` is configured automatically for:`}
        <ul parentName="li">
          <li parentName="ul">{`A gRPC client`}</li>
          <li parentName="ul">{`A Thrift client`}</li>
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
            }}>{`type://WebClient`}</a>{` when it's used with `}<a parentName="li" {...{
              "href": "type://ResponseAs:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseAs.html"
            }}>{`type://ResponseAs`}</a>{`. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/4236"
            }}>{`#4236`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Better performance for unary gRPC call. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4192"
        }}>{`#4192`}</a></li>
      <li parentName="ul">{`Annotated services perform better on unary responses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4177"
        }}>{`#4177`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You can now run `}<inlineCode parentName="li">{`ServerInterceptors`}</inlineCode>{` in a blocking task executor when
`}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useBlockingTaskExecutor(boolean)"
        }}>{`type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean)`}</a>{` is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4275"
        }}>{`#4275`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4331"
        }}>{`#4331`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Multiparts#getBoundary(MediaType):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multiparts.html#getBoundary(com.linecorp.armeria.common.MediaType)"
        }}>{`type://Multiparts#getBoundary(MediaType)`}</a>{` now throws an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` instead
of `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when a boundary is missing. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4193"
        }}>{`#4193`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4325"
        }}>{`#4325`}</a></li>
      <li parentName="ul">{`Armeria retrofit streaming mode now requests data to upstream when it does not have one. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4319"
        }}>{`#4319`}</a></li>
      <li parentName="ul">{`You no longer see deep recursive calls in `}<a parentName="li" {...{
          "href": "type://PublisherBasedStreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/PublisherBasedStreamMessage.html"
        }}>{`type://PublisherBasedStreamMessage`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4298"
        }}>{`#4298`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{` indicating the prohibited character of a header name
in an environment where Spring integration module and Netty client are used together.
(e.g. Spring Cloud Gateway) `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4293"
        }}>{`#4293`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerCacheControl#DISABLED:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ServerCacheControl.html#DISABLED"
        }}>{`type://ServerCacheControl#DISABLED`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ServerCacheControl#REVALIDATED:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ServerCacheControl.html#REVALIDATED"
        }}>{`type://ServerCacheControl#REVALIDATED`}</a>{` now have
`}<inlineCode parentName="li">{`max-age`}</inlineCode>{` set to `}<inlineCode parentName="li">{`0`}</inlineCode>{` for better compatibility with old browsers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4290"
        }}>{`#4290`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4291"
        }}>{`#4291`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` from `}<a parentName="li" {...{
          "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
        }}>{`type://Flags`}</a>{`
when `}<a parentName="li" {...{
          "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
        }}>{`type://RequestContextExportingAppender`}</a>{` is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4285"
        }}>{`#4285`}</a></li>
      <li parentName="ul">{`You no longer see the `}<inlineCode parentName="li">{`InputStream`}</inlineCode>{` returned by `}<a parentName="li" {...{
          "href": "type://StreamMessage#toInputStream(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#toInputStream(java.util.function.Function)"
        }}>{`type://StreamMessage#toInputStream(Function)`}</a>{` blocks
indefinitely in a certain situation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4268"
        }}>{`#4268`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4271"
        }}>{`#4271`}</a></li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "type://WriteTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WriteTimeoutException.html"
        }}>{`type://WriteTimeoutException`}</a>{` is now thrown when a header only request times out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4255"
        }}>{`#4255`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4259"
        }}>{`#4259`}</a></li>
      <li parentName="ul">{`The request now completes even when the content sanitizer throws an exception. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4248"
        }}>{`#4248`}</a></li>
      <li parentName="ul">{`A proxy client doesn't resolve a hostname anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4244"
        }}>{`#4244`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4245"
        }}>{`#4245`}</a></li>
      <li parentName="ul">{`The Spring Boot integration module doesn't create a new `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` anymore every time
it's injected. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4240"
        }}>{`#4240`}</a></li>
      <li parentName="ul">{`Armeria server sends now 400 Bad Request when an HTTP/2 upgrade request contains invalid headers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4016"
        }}>{`#4016`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4224"
        }}>{`#4224`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EurekaUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListener.html"
        }}>{`type://EurekaUpdatingListener`}</a>{` now registers itself with the `}<inlineCode parentName="li">{`instanceId`}</inlineCode>{` which is `}<inlineCode parentName="li">{`hostname:appname:port`}</inlineCode>{`,
so it doesn't cause a conflict when more than one app with the same name are running in the same host. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4223"
        }}>{`#4223`}</a></li>
      <li parentName="ul">{`A ramping up `}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html"
        }}>{`type://EndpointSelectionStrategy`}</a>{` now works correctly in combination with
`}<a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4221"
        }}>{`#4221`}</a></li>
      <li parentName="ul">{`The information of a thrown exception in gRPC service is now propagated to the client via the
`}<inlineCode parentName="li">{`grpc-status-details-bin`}</inlineCode>{` header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4203"
        }}>{`#4203`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4204"
        }}>{`#4204`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AbstractEndpointSelector#select(ClientRequestContext,ScheduledExecutorService,long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/AbstractEndpointSelector.html#select(com.linecorp.armeria.client.ClientRequestContext,java.util.concurrent.ScheduledExecutorService,long)?full"
        }}>{`type://AbstractEndpointSelector#select(ClientRequestContext,ScheduledExecutorService,long)?full`}</a>{`
is deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://AbstractEndpointSelector#select(ClientRequestContext,ScheduledExecutorService):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/AbstractEndpointSelector.html#select(com.linecorp.armeria.client.ClientRequestContext,java.util.concurrent.ScheduledExecutorService)?full"
        }}>{`type://AbstractEndpointSelector#select(ClientRequestContext,ScheduledExecutorService)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4246"
        }}>{`#4246`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ThriftSerializationFormats#protocolFactory(SerializationFormat):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#protocolFactory(com.linecorp.armeria.common.SerializationFormat)?full"
        }}>{`type://ThriftSerializationFormats#protocolFactory(SerializationFormat)?full`}</a>{` is deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://ThriftSerializationFormats#protocolFactory(SerializationFormat,int,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#protocolFactory(com.linecorp.armeria.common.SerializationFormat,int,int)?full"
        }}>{`type://ThriftSerializationFormats#protocolFactory(SerializationFormat,int,int)?full`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4226"
        }}>{`#4226`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BINARY`}</inlineCode>{`, `}<inlineCode parentName="li">{`COMPACT`}</inlineCode>{`, `}<inlineCode parentName="li">{`JSON`}</inlineCode>{`, `}<inlineCode parentName="li">{`TEXT`}</inlineCode>{`, and `}<inlineCode parentName="li">{`TEXT_NAMED_ENUM`}</inlineCode>{` in `}<a parentName="li" {...{
          "href": "type://ThriftProtocolFactories:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftProtocolFactories.html"
        }}>{`type://ThriftProtocolFactories`}</a>{` are deprecated
in favor of corresponding factory methods. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4226"
        }}>{`#4226`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://StreamMessage#of(Path,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.nio.file.Path,int)?full"
        }}>{`type://StreamMessage#of(Path,int)?full`}</a>{`, `}<a parentName="li" {...{
          "href": "type://StreamMessage#of(Path,ByteBufAllocator,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.nio.file.Path,io.netty.buffer.ByteBufAllocator,int)?full"
        }}>{`type://StreamMessage#of(Path,ByteBufAllocator,int)?full`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://StreamMessage#of(Path,ExecutorService,ByteBufAllocator,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.nio.file.Path,java.util.concurrent.ExecutorService,io.netty.buffer.ByteBufAllocator,int)?full"
        }}>{`type://StreamMessage#of(Path,ExecutorService,ByteBufAllocator,int)?full`}</a>{` are deprecated
in favor of `}<a parentName="li" {...{
          "href": "type://StreamMessage#builder(Path):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#builder(java.nio.file.Path)?full"
        }}>{`type://StreamMessage#builder(Path)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4058"
        }}>{`#4058`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceConfig#shutdownBlockingTaskExecutorOnStop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceConfig.html#shutdownBlockingTaskExecutorOnStop()"
        }}>{`type://ServiceConfig#shutdownBlockingTaskExecutorOnStop()`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ServiceConfig#shutdownAccessLogWriterOnStop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceConfig.html#shutdownAccessLogWriterOnStop()"
        }}>{`type://ServiceConfig#shutdownAccessLogWriterOnStop()`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ServerConfig#shutdownBlockingTaskExecutorOnStop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#shutdownBlockingTaskExecutorOnStop()"
        }}>{`type://ServerConfig#shutdownBlockingTaskExecutorOnStop()`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://ServerConfig#shutdownWorkerGroupOnStop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#shutdownWorkerGroupOnStop()"
        }}>{`type://ServerConfig#shutdownWorkerGroupOnStop()`}</a>{` are not used anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4280"
        }}>{`#4280`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ByteStreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ByteStreamMessage.html"
        }}>{`type://ByteStreamMessage`}</a>{` is returned instead of `}<inlineCode parentName="li">{`StreamMessage<HttpData>`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4058"
        }}>{`#4058`}</a>
        <ul parentName="li">
          <li parentName="ul">{`e.g `}<a parentName="li" {...{
              "href": "type://StreamMessage#of(File):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.io.File)?full"
            }}>{`type://StreamMessage#of(File)?full`}</a>{`, `}<a parentName="li" {...{
              "href": "type://SplitHttpMessage#body():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SplitHttpMessage.html#body()"
            }}>{`type://SplitHttpMessage#body()`}</a>{`,
`}<a parentName="li" {...{
              "href": "type://Multipart#toStreamMessage():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html#toStreamMessage()"
            }}>{`type://Multipart#toStreamMessage()`}</a>{` and `}<a parentName="li" {...{
              "href": "type://BodyPart#content():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html#content()"
            }}>{`type://BodyPart#content()`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpService.exchangeType(RequestHeaders, Route)`}</inlineCode>{` is now
`}<a parentName="li" {...{
          "href": "type://HttpService#exchangeType(RoutingContext):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html#exchangeType(com.linecorp.armeria.server.RoutingContext)"
        }}>{`type://HttpService#exchangeType(RoutingContext)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4177"
        }}>{`#4177`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.13.8 → 5.13.9`}</li>
      <li parentName="ul">{`Bucket4J 7.4.0 → 7.5.0`}</li>
      <li parentName="ul">{`Dropwizard metrics 4.2.9 → 4.2.10`}</li>
      <li parentName="ul">{`GraphQL 1.8.0 → 1.8.2`}</li>
      <li parentName="ul">{`gRPC-Java 1.45.1 → 1.47.0`}</li>
      <li parentName="ul">{`gRPC-Kotlin 1.2.1 → 1.3.0`}</li>
      <li parentName="ul">{`Jackson 2.13.2.1 → 2.13.3`}</li>
      <li parentName="ul">{`Kotlin 1.6.20 → 1.7.0`}</li>
      <li parentName="ul">{`Kotlinx 1.6.1 → 1.6.3`}</li>
      <li parentName="ul">{`Micrometer 1.8.5 → 1.9.1`}</li>
      <li parentName="ul">{`Netty 4.1.76.Final → 4.1.78.Final`}</li>
      <li parentName="ul">{`Netty incubator 0.0.13.Final → 0.0.14.Final`}</li>
      <li parentName="ul">{`Project Reactor 3.4.17 → 3.4.19`}</li>
      <li parentName="ul">{`Prometheus Simpleclient 0.15.0 → 0.16.0`}</li>
      <li parentName="ul">{`Reactive Streams 1.0.3 → 1.0.4`}</li>
      <li parentName="ul">{`RxJava 3.1.4 → 3.1.5`}</li>
      <li parentName="ul">{`ScalaPB 0.11.10 → 0.11.11`}</li>
      <li parentName="ul">{`Spring 5.3.19 → 5.3.21`}</li>
      <li parentName="ul">{`Spring Boot 2.6.6 → 2.7.1`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['ahnyujin', 'be-hase', 'devdynam0507', 'eisig', 'ghkim3221', 'ikhoon', 'injae-kim', 'Jimexist', 'jrhee17', 'kezhenxu94', 'kojilin', 'ks-yim', 'litols', 'mauhiz', 'minwoox', 'natsumehu', 'ngyukman', 'ta7uw', 'timothy-xflowpay', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      